import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const initialState = {
  isLoading: false,
  error: null,
  promotions: [],
  promotion: null,
};

const slice = createSlice({
  name: "promotion",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getPromotionsSuccess(state, action) {
      state.isLoading = false;
      state.promotions = action.payload;
    },

    getPromotionSuccess(state, action) {
      state.isLoading = false;
      state.promotion = action.payload;
    },
  },
});

export default slice.reducer;

export function getPromotions(levelId, venueId ) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${backendUrl}/promotion`, {
          
        params: {
          venueId,
          levelId,
          isDeleted: false,
          limit: 100000000
        },
      });

      dispatch(slice.actions.getPromotionsSuccess(response.data.results));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPromotion(promotionId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${backendUrl}/promotion/${promotionId}`);
      dispatch(slice.actions.getPromotionSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function deletePromotionById(deletedId,deletedById) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`${backendUrl}/promotion/${deletedId}`, {
        params: {
          deletedById,
          deletedBy:"venueUser",
        },
      });
      dispatch(slice.actions.getPromotionsSuccess(response.data));
      return { data: response?.data, status: response.status };
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

