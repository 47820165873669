import sum from "lodash/sum";
import uniq from "lodash/uniq";
import uniqBy from "lodash/uniqBy";
import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  items: [],
  item: null,
  openModal: false,
  openModalOrderDetail: false,
  openModalGalleryDetail: false,
};

const slice = createSlice({
  name: "item",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CATEGORIES
    getItemsSuccess(state, action) {
      state.isLoading = false;
      state.items = action.payload;
    },

    // GET CATEGORY
    getItemSuccess(state, action) {
      state.isLoading = false;
      state.item = action.payload;
    },

    onOpenModalGallerydetailPopup(state) {
      state.openModalGalleryDetail = true;
    },

    onCloseModalForGalleryDetail(state) {
      state.openModalGalleryDetail = false;
    },

    // CLOSE MODAL
    onCloseModal(state) {
      state.openModalGalleryDetail = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  onCloseModalForGalleryDetail,

  onCloseModal,

  onOpenModalGallerydetailPopup,
} = slice.actions;

// ----------------------------------------------------------------------

export function getItems(levelId, venueId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${backendUrl}/items?levelId=${levelId}&venueId=${venueId}&limit=10000`,
        {
          // params: {
          //   isDeleted: false
          // }
        }
      );
      dispatch(slice.actions.getItemsSuccess(response.data.results));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getItem(itemId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${backendUrl}/items/${itemId}`);
      dispatch(slice.actions.getItemSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateItemAvailability(itemId, availabilityData, token) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const payload = {}

      if (availabilityData.field === "isAvailableOnWebApp") {
        payload.isAvailableOnWebApp = availabilityData.value;
      }
      if (availabilityData.field === "isAvailableOnPos") {
        payload.isAvailableOnPos = availabilityData.value;
      }
      const response = await axios.patch(
        `${backendUrl}/items/${itemId}/updateAvailable`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      dispatch(slice.actions.getItemSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

