import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    menuType: "pos",
};
const slice = createSlice({
    name: 'menuType',
    initialState,
    reducers: {
        setMenusSuccess(state, action) {
            state.menuType = action.payload;
        },
        reset() {
            return initialState;
        },
    },
});

export default slice.reducer;
const { setMenusSuccess, reset } = slice.actions;

export function ChangeMenuType(type) {
    console.log('dispatch',type);
    
    return async (dispatch) => {
        try {
            dispatch(setMenusSuccess(type));
        } catch (error) {
            dispatch(reset());
        }
    };
}

export function resetMennType() {
    return (dispatch) => {
        dispatch(reset());
    };
}
