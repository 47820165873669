import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const initialState = {
  isLoading: false,
  error: null,
  recipes: [],
  recipe: null,
};

const slice = createSlice({
  name: "recipe",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getRecipesSuccess(state, action) {
      state.isLoading = false;
      state.recipes = action.payload;
    },
    getRecipeSuccess(state, action) {
      state.isLoading = false;
      state.recipe = action.payload;
    },
  },
});

export default slice.reducer;

export function getRecipe(recipeId, getToken) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${backendUrl}/recipe/${recipeId}`, {
        headers: {
          Authorization: `Bearer ${getToken}`,
        },

      });
      dispatch(slice.actions.getRecipeSuccess(response?.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteRecipe(getToken,recipeId, deletedById) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`${backendUrl}/recipe/${recipeId}/`, {
        headers: {
          Authorization: `Bearer ${getToken}`,
        },
        params: {
          deletedById: deletedById,
          deletedBy: "venueUser",
        },

      });
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getRecipes(getToken,venueId,levelId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`${backendUrl}/recipe`, {
        headers: {
          Authorization: `Bearer ${getToken}`,
        },
        params: {
          venueId: venueId,
          levelId: levelId,
          isDeleted:false,   
          limit: 10000000    
        },
      });
      dispatch(slice.actions.getRecipesSuccess(response?.data?.results));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}




