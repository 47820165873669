import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const initialState = {
  isLoading: false,
  error: null,
  categories: [],
  terminalCategories: [],
  category: null,
};

const slice = createSlice({
  name: "category",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CATEGORIES
    getCategoriesSuccess(state, action) {
      state.isLoading = false;
      state.categories = action.payload;
    },
    // GET TERMINAL-SPECIFIC CATEGORIES
    getTerminalCategoriesSuccess(state, action) {
      state.isLoading = false;
      state.terminalCategories = action.payload;
    },

    // GET CATEGORY
    getCategorySuccess(state, action) {
      state.isLoading = false;
      state.category = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
// export const {
// } = slice.actions;

// ----------------------------------------------------------------------

export function getCategories(levelId, venueId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const params = new URLSearchParams({
        levelId,
        venueId,
        limit: '100000',
      });
      const url = `${backendUrl}/categories?${params.toString()}`;
      const response = await axios.get(url);
      dispatch(slice.actions.getCategoriesSuccess(response.data.results));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getTerminalCategories(levelId, venueId, isAvailableOnPos, posCart) {
  return async (dispatch) => {
   dispatch(slice.actions.startLoading());
    try {
      const params = new URLSearchParams({
        levelId,
        venueId,
        limit: '100000',
      });
      if (isAvailableOnPos !== undefined) {
        params.append('isAvailableOnPos', isAvailableOnPos);
      }
      if (posCart && posCart.type === 'web' && posCart.isOrderEdit === true) {
        params.append('isAvailableOnWebApp', true);
      }
      const url = `${backendUrl}/categories?${params.toString()}`;
      const response = await axios.get(url);
      dispatch(slice.actions.getTerminalCategoriesSuccess(response.data.results));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


// ----------------------------------------------------------------------

export function getCategory(categoryId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `${backendUrl}/categories/${categoryId}`
      );
      dispatch(slice.actions.getCategorySuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
